var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'cart-actions',
    { 'without-after' : _vm.categories && _vm.categories.length === 0 }
  ]},[_c('div',{staticClass:"cart-actions-barcode",on:{"click":_vm.openNumpad,"touchstart":function($event){return _vm.touchStart($event)},"touchend":function($event){return _vm.touchEnd($event);},"touchcancel":function($event){return _vm.touchEnd($event)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.barcode.headline'))}})]),_c('div',{staticClass:"cart-actions-inner"},[(_vm.categories && _vm.categories.length > 0)?_c('div',{class:[
        'categories',
        { 'scroll' : _vm.categories && _vm.categories.length > 3 }
      ]},[_c('div',{staticClass:"categories-inner"},[_c('div',{staticClass:"headline",domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.categories.headline'))}}),_c('div',{staticClass:"buttons"},_vm._l((_vm.categories),function(category,categoryIndex){return _c('div',{key:`categories-category-${categoryIndex}`,staticClass:"btn-default-grey btn-category",domProps:{"innerHTML":_vm._s(category.name)},on:{"click":function($event){_vm.activeCategory(category.id, category.name); _vm.dialogOpen()},"touchstart":function($event){return _vm.touchStart($event)},"touchend":function($event){return _vm.touchEnd($event);},"touchcancel":function($event){return _vm.touchEnd($event)}}})}),0)])]):_vm._e(),_c('div',{staticClass:"pay"},[_c('div',{staticClass:"pay-inner"},[_c('h3',{staticClass:"total-headline",domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.total.headline'))}}),_c('div',{staticClass:"total"},[_c('transition',{attrs:{"name":"scale","mode":"out-in"}},[_c('div',{key:_vm.total,staticClass:"total-number scale-animation"},[(_vm.showPriceFormat)?_c('span',{domProps:{"innerHTML":_vm._s(`${_vm.products.currencySymbol} ${_vm.priceFormat(_vm.total)}`)}}):_vm._e()])])],1),_c('transition',{attrs:{"name":"opacity","mode":"out-in"}},[_c('div',{staticClass:"done"},[_c('button',{staticClass:"btn-default btn-done",attrs:{"disabled":_vm.products.items && _vm.products.items.length === 0},domProps:{"innerHTML":_vm._s(`${_vm.$t('terminal.cart.total.donenew')}`)},on:{"click":function($event){return _vm.done()},"touchstart":function($event){return _vm.touchStart($event)},"touchend":function($event){return _vm.touchEnd($event);},"touchcancel":function($event){return _vm.touchEnd($event)}}})])]),_c('div',{staticClass:"abort",on:{"click":function($event){return _vm.resetStoreCart()}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('terminal.cart.total.abort'))}})])],1)])]),_c('dialog-numpad',{attrs:{"dialogNumpadVisible":_vm.dialogNumpadVisible,"closeNumpad":_vm.closeNumpad}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }